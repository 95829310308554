<template>
	<div class="mainBox" :class="!isMobile ? 'pcbox' : ''">
		<div class="headBox" v-if="gameInfo.gameFrontCover">
			<img style="width: 100%;" :src="gameInfo.gameFrontCover">
			<div class="descBox">
				<div class="iconBox cimg">
					<img :src="gameInfo.gameIcon">
				</div>
				<div class="detailBox">
					<p class="gameName">{{gameInfo.gameKrName}}</p>
					<p class="labels">
						<span v-if="gameInfo.gameType">{{gameInfo.gameType}}</span>
						<span v-if="gameInfo.gameKrName">{{gameInfo.gameKrName}}</span>
					</p>
					<p class="tags">
						<span v-for="(item,index) in formatTags(gameInfo.tags)" :key="index">{{item}}</span>
					</p>
				</div>
				<div :id="gameInfo.gameId == 865 ? 'google-phone-event-btn' : '' " class="btns border" @click.stop="handleDownload">
					<i class="mIcon"></i>{{ $t('home.gameDownload') }}
				</div>
				<template v-if="!isMobile">
					<!-- pc端下载地址判断未加 -->
					<div :class="{'google-pc-event-btn':gameInfo.gameId == 865}" v-if="gameInfo.pcUrl && gameInfo.ldUrl" class="btns pc" @click.stop="handlePcDownload">
						<i class="pcIcon"></i>{{ $t('home.pcDownload') }}
					</div>
				</template>
			</div>
		</div>
		<div class="headPcBox" v-else>
			<div class="imgicon"><img style="max-width: 100%;" :src="gameInfo.gameIcon" /></div>
			<div class="content">
				<h4>{{gameInfo.gameKrName}}</h4>
				<p>게임 장르：{{gameInfo.gameType}}</p>
				<p>출시시간：{{parseTime(gameInfo.openTime,'{y}-{m}-{d}')}}</p>
				<div class="btns border" @click.stop="handleDownload">
					<i class="mIcon"></i>{{ $t('home.gameDownload') }}
				</div>
				<!-- pc端下载地址判断未加 -->
				<div v-if="gameInfo.pcUrl && gameInfo.ldUrl" class="btns pc" @click.stop="handlePcDownload">
					<i class="pcIcon"></i>{{ $t('home.pcDownload') }}
				</div>
				<!-- <div class="btnBox" v-else @click.stop="handleDownload">
					{{ $t('home.gameDownload') }}
				</div> -->
			</div>
		</div>
		<van-tabs v-model:active="activeName" sticky :shrink="!isMobile" :border="!isMobile">
		  <van-tab :title="$t('common.detail')" name="a">
			  <div class="conBox">
				  <ul class="imgList">
					  <li class="cimg" :class="isMobile ? '' : 'pc'" v-for="(item,index) in formatImgs(gameInfo.gameFiveCharts)" :key="index">
						  <img :src="item" @click="handleImgPreview(formatImgs(gameInfo.gameFiveCharts),index)">
					  </li>
				  </ul>
				  <div class="descripe">
					  <h3>{{ $t('common.detail') }}</h3>
					  <p v-html="formatBr(gameInfo.gameSynopsis)"></p>
				  </div>
			  </div>
		  </van-tab>
		  <van-tab :title="$t('home.gameNotice')" name="e">
		  			  <div class="conBox">
		  				  <template v-if="noticeList.length > 0">
		  					  <!-- <div class="descripe" v-for="(item,index) in noticeList" :key="index">
		  						  <h3>{{ item.noticeTitle }}</h3>
		  						  <div class="txts" v-html="item.noticeContent"></div>
		  					  </div> -->
		  					  <van-collapse v-model="activeNotice" accordion>
		  					    <van-collapse-item :title="item.noticeTitle" :name="String(item.id)" v-for="(item,index) in noticeList" :key="index">
		  					      <div class="txts" v-html="item.noticeContent"></div>
		  					    </van-collapse-item>
		  					  </van-collapse>
		  
		  				  </template>
		  				  <p class="text-center" v-else>
		  					  {{ $t('common.noMore') }}
		  				  </p>
		  			  </div>
		  </van-tab>
		  <!-- <van-tab :title="$t('home.introduction')" name="b">
			  <van-notice-bar color="#50b9c0" background="#ecfeff" left-icon="notes-o">
				{{ $t('home.newstotal',{num:introductsTotal})}}
			  </van-notice-bar>
				<div class="conBox">
					<template v-if="introductsList.length > 0">
						<ul class="introductList">
							<li v-for="(item,index) in introductsList" :key="index" @click="handleDetail(item)">
								<p class="title">{{item.title}}</p>
								<p>{{item.source}}<span>{{parseTime(item.updateTime,'{m}-{d}')}}</span></p>
							</li>
						</ul>
					</template>
					<p class="text-center" v-else>
					  {{ $t('common.noMore') }}
					</p>
				</div>
		  </van-tab> -->
		  <van-tab :title="$t('home.offlineActivity')" name="c">
			  <div class="conBox">
				  <template v-if="activityList.length > 0">
					  <div class="descripe" v-for="(item,index) in activityList" :key="index">
						  <h3>{{ item.title }}</h3>
						  <div class="txts" v-html="item.contents"></div>
					  </div>
				  </template>
				  <p class="text-center" v-else>
					  {{ $t('common.noMore') }}
				  </p>
			  </div>
		  </van-tab>
		  <van-tab :title="$t('home.openService')" name="d">
			  <div class="conBox">
				  <template v-if="serviceList.length > 0">
						<van-cell-group inset>
						    <van-field v-for="(item,index) in serviceList" :key="index"
						      v-model="item.openTimeStr"
						      :label="item.serviceName"
							  :class="{'active':item.isHighlight == 1}"
						      input-align="right"
						    />
						</van-cell-group>
				  </template>
				  <p class="text-center" v-else>
					  {{ $t('common.noMore') }}
				  </p>
			  </div>
		  </van-tab>
		  
		</van-tabs>

		<van-action-bar safe-area-inset-bottom placeholder v-if="isMobile">
		  <van-action-bar-icon icon="revoke" :text="$t('common.back')" @click="handleBack" />
		  <!-- <van-action-bar-button type="primary" :text="$t('home.gamePlay')" v-if="gameInfo.pcUrl" @click="handlePlay" /> -->
		  <van-action-bar-button type="primary" :text="$t('common.download')" @click="handleDownload" />
		</van-action-bar>
		
		<!-- 下载弹窗 -->
		<van-popup v-model:show="showDownload" round teleport="body" >
			<popupDownload v-if="showDownload" :gameInfo="gameInfo" />
		</van-popup>
		
		<!-- 下载PC版弹窗 -->
		<van-popup v-model:show="showPcDownload" round teleport="body" :style="{ borderRadius: '28px' }" >
			<popupPcDownload v-if="showPcDownload" :gameInfo="gameInfo" />
		</van-popup>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import { showImagePreview } from 'vant';
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import { getGameBanner,getSearch,getActivity,getIntroduction,getDownloadUrl,getGameService,getNoticeList } from "@/api/index";
	import popupDownload from '@/components/Popup/download.vue'
	import popupPcDownload from '@/components/Popup/pcdownload.vue'
	
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const gameInfo = ref({})
	const activeName = ref('a')
	const activeNotice = ref('0')
	const showDownload = ref(false)
	const showPcDownload = ref(false)
	const activityList = ref([])
	const introductsTotal = ref(0)
	const introductsList = ref([])
	const noticeList = ref([])
	const serviceList = ref([])
	const gmDownloadUrl = ref('')
	
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	
	const lang = proxy.$i18n.locale
	const gmImg = require('@/assets/images/'+lang+'/gm_download.png')
	const googleImg = require('@/assets/images/'+lang+'/google_download.png')
	const onestoreImg = require('@/assets/images/'+lang+'/onestore_download.png')
	const downloadTips = require('@/assets/images/'+lang+'/coupon_tips.png')
	
	onMounted(() => {
		const query = router.currentRoute.value.params
		const params = router.currentRoute.value.query
		getSearch(query).then((response)=>{
			gameInfo.value = response.data || {};
		})
		getActivity(query.id).then((response) => {
			activityList.value = response.data || [];
		})
		getIntroduction(query.id).then((response) => {
			introductsList.value = response.data || [];
			introductsTotal.value = response.msg
		})
		if(query.id == 811){
			useConfigStore().setIsLuoli(true)
		}else{
			useConfigStore().setIsLuoli(false)
		}
		if(query.platid){
			getDownloadUrl(query.id,query.platid).then((response) => {
				gmDownloadUrl.value = response.data || ''
			})
		}
		getGameService(query.id).then((res) => {//开服信息
			serviceList.value = res.data
		})
		getNoticeList({ gameId: query.id }).then((res) => {
			noticeList.value = res.data
			if(params.noticeId){
				activeName.value = 'e'
				activeNotice.value = params.noticeId
			}else{
				if(res.data.length > 0){
					activeNotice.value = res.data[0].id
				}
			}
		})
	})
	const formatImgs = (str) => {
		let arr = []
		if(!str){
			return arr
		}
		if(str && str.indexOf(',') > 0){
			arr = str.split(',')
		}else if(str.indexOf(',') == -1){
			arr = [str]
		}
		return arr
	}
	const formatTags = (str) => {
		if(!str){
			return []
		}
		if(str.indexOf(',') > -1){
			return str.split(',')
		}else{
			return [str]
		}
		
	}
	const handleImgPreview = (list,index) => {
		showImagePreview({
		  images: list,
		  startPosition: index,
		  closeable: true
		});
	}
	const handleBack = () => {
		history.back()
	}
	const handleDownload = () => {
		if(gameInfo.value.gmLink || gameInfo.value.googleLink || gameInfo.value.onestoreLink){
			showDownload.value = true
		}else{
			proxy.$toast('没有下载地址')
		}
	}
	const handlePcDownload = () => {
		showPcDownload.value = true
	}
	const handlePlay = () => {
		if(isMobile.value){
			router.push({
			  path:'/gameplay/'+gameInfo.value.gameId
			});
		}else{
			window.open(gameInfo.value.pcUrl,'_blank')
		}
	}
	const handleDetail = (row) => {
		router.push({
		  path:'/introduct/'+row.id
		});
	}
</script>

<style lang="less" scoped>
	.van-cell{
		&.active{
			background: #fef0f0;
			/deep/.van-field__label,/deep/.van-field__control{
				color: #f56c6c;
			}
		}
	}
	.pcbox{
		.conBox{
			background: #fff;min-height: 300px;margin-bottom: 20px;
		}
		.headBox{
			margin: 0 0 20px;
		}
		.headPcBox{
			width: 100%;background: #fff;box-shadow: 0 0 20px #eee;padding: 30px;box-sizing: border-box;display: flex;
			margin: 20px 0;
			.imgicon{
				width: 180px;border-radius: 36px;overflow: hidden;
				img{width: 100%;}
			}
			.content{
				flex: 1;padding-left: 30px;
				h4{font-size: 36px;font-weight: 500;margin-bottom: 10px;}
				p{font-size: 14px;line-height: 30px;}
				// .btnBox{
				// 	margin-top: 20px;display: inline-block;
				// 	font-size: 0.8125rem;cursor: pointer;
				// 	background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
				// 	padding: 0.25rem 0.625rem;border-radius: 0.3125rem;
				// }
				.btns{
					flex-shrink: 0;cursor: pointer;
					font-size: 14px;width: 130px;height: 32px;
					color: #fff;
					border: 1px solid transparent;
					border-radius: 16px;
					background-origin: border-box;
					background-clip: content-box, border-box;
					background-image: linear-gradient(to right,#51BAC0,#85D6DA),
					    linear-gradient(to right,#51BAC0,#85D6DA);
					padding: 0;box-sizing: border-box;
					display: flex;align-items: center;justify-content: center;
					&.border{
						color: #51BAC0;
						background-image: none;
						border: 1px solid #51BAC0;
					}
					.mIcon{
						width: 16px;height: 16px;display: inline-block;margin-right: 5px;
						background: url('~@/assets/images/common/m_icon.png') no-repeat center;
						background-size: auto 100%;
					}
					.pcIcon{
						width: 16px;height: 16px;display: inline-block;margin-right: 8px;
						background: url('~@/assets/images/common/pc_icon.png') no-repeat center;
						background-size: auto 100%;
					}
					&.pc{
						margin-left: 16px;height: 52px;font-size: 20px;border-radius: 12px;
						width: 160px;
					}
				}
			}
		}
	}
	.mainBox{
		width: 100%;height: calc(100% - 5.625rem);overflow-y: scroll;
	}
	/deep/ .van-action-bar{
		position: absolute;padding: 0 0.625rem;
		.van-action-bar-icon{
			margin-right: 0.75rem;
		}
	}
	.headBox{
		position: relative;width: 100%;height: auto;min-height: 6.25rem;
		.descBox{
			display: flex;align-items: center;position: absolute;bottom: 0;left: 0;
			width: 100%;padding: 1.25rem 1rem 0.5rem;box-sizing: border-box;flex: 1;
			background: linear-gradient(to bottom,transparent,rgba(0,0,0,0.9));
			// .btnBox{
			// 	font-size: 0.8125rem;cursor: pointer;
			// 	background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
			// 	padding: 0.25rem 0.625rem;border-radius: 0.3125rem;
			// }
			.btns{
				flex-shrink: 0;cursor: pointer;
				font-size: 14px;width: 130px;height: 32px;
				color: #fff;
				border: 1px solid transparent;
				border-radius: 16px;
				background-origin: border-box;
				background-clip: content-box, border-box;
				background-image: linear-gradient(to right,#51BAC0,#85D6DA),
			        linear-gradient(to right,#51BAC0,#85D6DA);
				padding: 0;box-sizing: border-box;
				display: flex;align-items: center;justify-content: center;
				&.border{
					color: #51BAC0;
					background-image: none;
					border: 1px solid #51BAC0;
				}
				.mIcon{
					width: 16px;height: 16px;display: inline-block;margin-right: 5px;
					background: url('~@/assets/images/common/m_icon.png') no-repeat center;
					background-size: auto 100%;
				}
				.pcIcon{
					width: 16px;height: 16px;display: inline-block;margin-right: 8px;
					background: url('~@/assets/images/common/pc_icon.png') no-repeat center;
					background-size: auto 100%;
				}
				&.pc{
					margin-left: 16px;height: 52px;font-size: 20px;border-radius: 12px;
					width: 160px;
				}
			}
			.iconBox{
				flex-shrink: 0;width: 4.5rem;height: 4.5rem;border-radius: 0.75rem;
				margin-right: 0.75rem;
			}
			.detailBox{
				flex: 1;
				.gameName{
					font-size: 0.9375rem;font-weight: 500;color: #fff;margin-bottom: 0.1875rem;
				}
				.labels{
					font-size: 0.8125rem;color: #fff;margin-bottom: 0.1875rem;
					display: inline-flex;align-items: center;width: 100%;
					span{
						display: inline-block;height: 0.75rem;line-height: 0.75rem;
					}
					span + span{
						padding-left: 0.3125rem;
						border-left: 0.0625rem solid #fff;margin-left: 0.3125rem;
					}
				}
				.tags{
					display: inline-flex;align-items: center;justify-content: flex-start;
					span{
						display: inline-block;border: 1px solid #f42ab4;color: #f42ab4;
						font-size: 0.75rem;padding: 0 0.3125rem;border-radius: 0.3125rem;
						&:nth-child(even){
							border: 1px solid #FB9823;color: #FB9823;
						}
					}
					span + span{
						margin-left: 0.3125rem;
					}
				}
				.notice{
					color: #fff;font-size: 0.8125rem;margin-top: 0.1875rem;
				}
			}
		}
	}
	.conBox{
		width: 100%;padding: 0.75rem;box-sizing: border-box;
		ul.imgList{
			display: flex;align-items: center;justify-content: flex-start;flex-wrap: nowrap;
			overflow-x: scroll;
			li{
				width: 80%;height: 10rem;flex-shrink: 0;
				&.pc{
					max-width: 18.75rem;
				}
			}
			li + li{margin-left: 0.5rem;}
		}
		ul.introductList{
			li{
				width: 100%;margin-bottom: 0.625rem;padding: 0.5rem 0.75rem;box-sizing: border-box;
				box-shadow: 0 0.1875rem 0.625rem 0px #eFeFeF;border-radius: 0.5rem;
				p{
					font-size: 0.75rem;color: #90939A;min-height: 1.125rem;
					span{
						display: inline-block;
						float: right;
					}
					&.title{
						font-size: 0.875rem;font-weight: 500;color: #14181B;margin-bottom: 0.3125rem;
					}
				}
			}
		}
		.descripe{
			margin: 1rem 0;
			h3{
				margin-bottom: 0.75rem;
			}
			p,.txts{
				line-height: 1.25rem;font-size: 0.8125rem;color: #6b6b6b;
				/deep/ img{
					max-width: 100%;
				}
			}
		}
	}
</style>